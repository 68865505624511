import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { HeadingBasic } from "../../components/heading/heading"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { NavTabs, TabPane } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Functions
   ========================================================================== */
const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

// sort by year in descending order
const compareElementYear = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

/* ==========================================================================
    Component
   ========================================================================== */
const ReportsSection = ({ reports }) => {
  let reportGroupByYear = reports && groupBy(reports, "year")
  // split annualGroupByYear to an array
  const reportArr =
    (reportGroupByYear &&
      Object.keys(reportGroupByYear).map(key => [
        key,
        reportGroupByYear[key],
      ])) ||
    []

  let documentItems = []

  reportArr.length > 0 &&
    reportArr.map((reportItem, i) => {
      let reportData = []
      reportItem[1].map(item => {
        reportData.push({
          title: item.node.title,
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
        return 1
      })

      documentItems.push(
        <div key={`${reportItem[0]}-${i}`} className="mb-5">
          <Document
            title={reportItem[0]}
            section="Public Expose"
            type="annual"
            data={reportData}
          />
        </div>
      )

      return 1
    })

  return (
    <section className="report">
      {documentItems.length > 0 && documentItems.sort(compareElementYear)}
      {documentItems.length === 0 && <div></div>}
    </section>
  )
}

/* ==========================================================================
    Render
   ========================================================================== */

const PublicExpose = location => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgVideoCover: file(
        relativePath: { eq: "home/bg_home_cover-hero-video.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      wordPress {
        pageBy(uri: "public-expose") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        publicExpose: fileCategory(id: "public-expose", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const publicExposeGroupedByCompany =
    data.wordPress.publicExpose &&
    groupBy(data.wordPress.publicExpose.filesPdf.edges, "company")

  return (
    <Layout location={location} currentLocation={location.uri} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "The Investor Relations Section is dedicated for shareholders and investors. Here you will find updated public expose from Sinarmas Limited, PT. Bumi Serpong Damai Tbk, and PT. Duta Pertiwi Tbk",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}

      <Container>
        <NavTabs
          scrollable
          className="pt-4"
          data={[
            {
              name: "Sinarmas Land Limited",
              target: "sinarmasland",
            },
            { name: "PT Bumi Serpong Damai Tbk", target: "bsd" },
            {
              name: "PT Duta Pertiwi Tbk",
              target: "dutaPertiwi",
            },
          ]}
        />
      </Container>

      {publicExposeGroupedByCompany && (
        <>
          <TabPane id="sinarmasland" className="py-lg-5 pt-5 pt-lg-0 active">
            <ReportsSection
              reports={
                publicExposeGroupedByCompany &&
                publicExposeGroupedByCompany["Sinarmas Land Limited"] !==
                  undefined &&
                publicExposeGroupedByCompany["Sinarmas Land Limited"]
              }
            />
          </TabPane>
          <TabPane id="bsd" className="py-lg-5 pt-5 pt-lg-0">
            <ReportsSection
              id="bsd"
              reports={
                publicExposeGroupedByCompany &&
                publicExposeGroupedByCompany["PT Bumi Serpong Damai Tbk"]
              }
            />
          </TabPane>
          <TabPane id="dutaPertiwi" className="py-lg-5 pt-5 pt-lg-0">
            <ReportsSection
              reports={
                publicExposeGroupedByCompany &&
                publicExposeGroupedByCompany["PT Duta Pertiwi Tbk"]
              }
              id="dutaPertiwi"
            />
          </TabPane>
        </>
      )}
    </Layout>
  )
}

export default PublicExpose
